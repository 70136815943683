import "./intro.css"
import { useContext } from "react";
import { ThemeContext } from "../../context";
import { useParallax } from 'react-scroll-parallax';


const Intro = () => {

  const { ref } = useParallax({ speed: 60 });
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
    return (
      <div 
      style={{backgroundColor: darkMode ? "white" : "#111",
                 color: darkMode ? "black" : "#F0EAD6",
                 borderBottom: darkMode ? "#dddddd solid 1px" : "black solid 1px",
                 }}
    >
        <div className="i">
            <div className="i-left">
                <div className="i-left-wrapper">
                        <h2 className="i-intro">Hello, I am</h2>
                        <h1 className="i-name">Feltpro</h1>
                        <div className="i-title">
                            <div className="i-title-wrapper">
                                <div className="i-title-item">Web Developer</div>
                                <div className="i-title-item">UI/UX Designer</div>
                                <div className="i-title-item">Blogger</div>
                                <div className="i-title-item">Writer</div>
                                <div className="i-title-item">Content Creator</div>
                                <div className="i-title-item">Front-end</div>
                                <div className="i-title-item">Educator</div>  
                            </div>
                        </div>
                        <p className="i-desc">
                            I design and develop apps, specialising in Front-end development.
                        </p>
                </div>
                <svg
          width="75"
          height="75"
          viewBox="0 0 75 75"
          fill="none"
          stroke="black"
          className="i-scroll"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="scroll">
            <path
              id="Vector"
              d="M40.5 15L34.5 9L28.5 15"
              stroke-width="3"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Vector_2"
              d="M28.5 24L34.5 30L40.5 24"
              stroke-width="3"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <g id="Group">
              <path
                id="Vector_3"
                d="M9 37.5H60"
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <path
              id="Vector_4"
              d="M34.5 27V9"
              stroke-width="2.9895"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <g id="Group_2">
              <path
                id="Vector_5"
                d="M9 27C9 12.918 20.418 1.5 34.5 1.5C48.5859 1.5 60 12.918 60 27C60 29.8906 60 45.1094 60 48C60 62.082 48.5859 73.5 34.5 73.5C20.418 73.5 9 62.082 9 48C9 45.1094 9 29.8906 9 27Z"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
        </svg>
            </div> 
                <div className="i-right">
                  <div class="heading-wrapper" ref={ref}>
                  <div 
                        style={{backgroundColor: darkMode ? "white" : "#111",
                        color: darkMode ? "black" : "#F0EAD6",
                        borderBottom: darkMode ? "#dddddd solid 1px" : "black solid 1px",
                        }} 
                        class="heading">Front-End development - Based in Bristol</div>
                  </div>
                    <img src="https://globalgrasshopper.com/wp-content/uploads/2020/12/Best-places-to-visit-in-Bristol.jpg" alt="" className="i-img" />
                </div>  
        </div>
      </div>
    )
}

export default Intro
