import "./toggle.css"
import Sun from "../../img/sun.png"
import Moon from "../../img/moon.png"
import { useContext } from "react"
import { ThemeContext } from "../../context";


const Toggle = () => {
    const theme = useContext(ThemeContext)
    const darkMode = theme.state.darkMode;

    const handleClick = () =>{
        theme.dispatch({type:"TOGGLE"})
    }
    return (
        <div 
        style={{backgroundColor: darkMode ? "#111" : "white",
                   color: darkMode && "#F0EAD6",
                   }}
      >
        <div className="t">
            <img src={Moon} alt="" className="t-icon" />
            <img src={Sun} alt="" className="t-icon" />
            <div className="t-button" onClick={handleClick} style={{left: theme.state.darkMode ? 0 : 25}}></div>
        </div>
        </div>
    )
}

export default Toggle
