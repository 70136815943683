export const api = [
    {
      id: 1,
      title: "Weather APP",
      img: "https://assets.materialup.com/uploads/cfe42246-e49f-4166-91ce-246e253eae27/preview.jpg",
        link: "https://feltpro.github.io/feltproweather/",
    },
    {
      id: 2,
      title: "Shakespeare Quote Generator",
      img:
      "https://cdn.britannica.com/90/215990-131-3356570D/Shakespeare-Reading-oil-painting-William-Page-1873-1874-Smithsonian-American-Museum-Art.jpg?w=1100",
        link: "https://feltpro.github.io/shakespeareQuotes/"
    },
    {
      id: 3,
      title: "Dog picture generator",
      img:
      "https://cdn.pixabay.com/photo/2017/09/25/13/12/cocker-spaniel-2785074__340.jpg",
        link: "https://feltpro.github.io/dogGenerator/",
    },
  ];
  
  export const javaScript = [
    {
      id: 1,
      title: "F1 Who Wants to Be a Millionaire",
      img: "https://www.formula1.com/content/fom-website/en/subscribe/download-the-f1-tv-app/_jcr_content/image16x9.img.1536.high.jpg",
      link: "https://feltpro.github.io/F1MillionaireQuiz/",
    },
    {
      id: 2,
      title: "Vanilla javaScript calculator",
      img: "https://images.all-free-download.com/images/graphiclarge/calculator_solar_calculator_count_239676.jpg",
      link: "https://feltpro.github.io/JavaScript-Calculator/",
    },
    {
        id: 3,
        title: "Weather APP",
        img: "https://assets.materialup.com/uploads/cfe42246-e49f-4166-91ce-246e253eae27/preview.jpg",
          link: "https://feltpro.github.io/feltproweather/",
      },
    {
      id: 4,
      title: "Self-Validating Form",
      img: "https://assets.justinmind.com/wp-content/uploads/2019/04/web-form-design-HEADER.png",
      link: "https://feltpro.github.io/reactvalidatedform/",
    },
  ];
  
  export const react = [
    {
      id: 1,
      title: "Ecommerce Website",
      img:
      "https://www.heypoorplayer.com/wp-content/uploads/2021/02/ghost-of-tsushima-background-block-01-ps4-en-21jul20.jpg",
      link: "https://feltpro.github.io/ecommerce-site/",
    },
    {
      id: 2,
      title: "F1 Who Wants to Be a Millionaire",
      img:
      "https://www.formula1.com/content/fom-website/en/subscribe/download-the-f1-tv-app/_jcr_content/image16x9.img.1536.high.jpg",
      link: "https://feltpro.github.io/F1MillionaireQuiz/",

    },
    {
      id: 3,
      title: "Personal Trainer Website",
      img:
        "https://cdn.pixabay.com/photo/2017/05/25/15/08/jogging-2343558__480.jpg",
      link: "https://feltpro.github.io/reactptwebsite/",
    },
    {
      id: 4,
      title: "Self-Validating Form",
      img: "https://assets.justinmind.com/wp-content/uploads/2019/04/web-form-design-HEADER.png",
      link: "https://feltpro.github.io/reactvalidatedform/",
    },
  ];
  
  export const sass = [
    {
        id: 1,
        title: "Personal Trainer Website",
        img:
          "https://cdn.pixabay.com/photo/2017/05/25/15/08/jogging-2343558__480.jpg",
        link: "https://feltpro.github.io/reactptwebsite/",
      },
  ];
  
  export const freelance = [
    {
      id: 1,
      title: "Personal Trainer Website",
      img:
        "https://cdn.pixabay.com/photo/2017/05/25/15/08/jogging-2343558__480.jpg",
      link: "https://feltpro.github.io/reactptwebsite/",
    },
  ];
