import { useEffect, useState } from "react";
import PortfolioList from "../portfolioList/PortfolioList";
import "./portfolio.scss";
import { 
        api,
        javaScript,
        react,
        sass,
        freelance,
        } from "../../data";
import { useContext } from "react";
import { ThemeContext } from "../../context";

export default function Portfolio() {
    const [selected, setSelected] = useState("api");
    const [data, setData] = useState([]);
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;

    const list = [
        {
            id: "api",
            title: "API",
          },
          {
            id: "javascript",
            title: "JS - OOP & Functional Programming",
          },
          {
            id: "react",
            title: "React",
          },
          {
            id: "sass",
            title: "SASS",
          },
          {
              id: "freelance",
              title: "Freelance Work"
          },
    ];

    useEffect(()=> {
        switch(selected){
            case "api":
                setData(api);
                break;
                case "javascript":
                setData(javaScript);
                break;
                case "react":
                setData(react);
                break;
                case "sass":
                setData(sass);
                break;
                case "freelance":
                setData(freelance);
                break;
                default: setData(api);
        }
    }, [selected])

    return (
          <div 
          style={{backgroundColor: darkMode ? "white" : "#111",
          color: darkMode ? "black" : "#F0EAD6",
          borderTop: darkMode ? "#dddddd  solid 1px" : "black solid 1px",
          borderBottom: darkMode ? "#dddddd  solid 1px" : "black solid 1px",
          }}
          >
        <div className="portfolio" id="portfolio">
            <h1>Portfolio</h1>
            <p>          I have sorted my projects below according to their top skill requirement. 
          My use of HTML5, CSS and JavaScript are fluent throughout
          but I have also studied and learned several other important features, from
          improving my UI design practices, to Frontend Frameworks and State Management
          with React. This portfolio website was made using React and showcases skills 
          such as: javaScript, when mapping data from Objects; light and dark mode, 
          CSS styled components and animations. I can program a little PHP too.  </p>
            <ul>
                {list.map(portfolioItem => (
                    <PortfolioList 
                        key={portfolioItem.id}
                        title={portfolioItem.title} 
                        active={selected === portfolioItem.id}
                        setSelected={setSelected}
                        id={portfolioItem.id}
                    />
                ))}
            </ul>
            <div className="container">
                {data.map((d) => (
                <div key={d.id} className="item">
                    <a href={d.link} target="_blank"><img src={d.img} /></a>
                    <h3>{d.title}</h3>
                    
                </div>
                ))}
            </div>
        </div>
        </div>
    )
}
