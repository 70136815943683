import "./about.css";
import Prof from "../../img/profThree.jpg";
import MyPDF from "../../docs/resume.pdf";
import { useContext } from "react";
import { ThemeContext } from "../../context";


const About = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  return (
  <div 
  style={{backgroundColor: darkMode ? "rgb(239, 249, 250)" : "black" ,
  color: darkMode ? "black" : "#F0EAD6", 
             }}
>
      <div className="a">
        <div className="a-left">
          <div 
          style={{backgroundColor: darkMode ? "white" : "#111",
          borderTop: darkMode ? "#ffffff  solid 1px" : "#111 solid 1px",
          borderBottom: darkMode ? "#ffffff solid 1px" : "#111 solid 1px",
          }}
           className="a-card bg"></div>
          <div className="a-card">
            <img src={Prof} 
            alt=""
            className="a-img"
          />
        </div>
      </div>
      <div className="a-right">
        <div className="a-rwrapper">
        <h1 className="a-title">About Me</h1>
        <p className="a-sub">
          Why Front-end development?
        </p>
        <p className="a-desc">
        Hi, I'm Simon and I love the challenges, task-oriented projects, and discovery of Web Design. I design useful, aesthetic products and the End-goal is to 
        be a full-stack developer.  
        When I'm not scratching my head over why Flexbox is doing what it is doing, I'll probably be cooking, running, or finding somewhere pleasant to walk. For my resume please
        contact me through one of the links below. 
        {/* <span> </span> 
        <a data-tooltip="This will download a pdf 😊" className="resume" href={MyPDF} download="resume.pdf"><strong> click on this link</strong></a>.      */}
        </p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default About


