import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Intro from "./components/intro/Intro";
import Toggle from "./components/toggle/Toggle";
import Portfolio from "./components/portfolio/Portfolio";
import { ParallaxProvider } from 'react-scroll-parallax';
 
const App = () => {
    return (
    <div>
        <Toggle />
        <ParallaxProvider>
        <Intro />
        </ParallaxProvider>
        <About />
        <Portfolio />
        <Contact />
    </div>
  ) 
};

export default App;